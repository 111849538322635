exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-tsx": () => import("./../../../src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ocpi-tsx": () => import("./../../../src/pages/ocpi.tsx" /* webpackChunkName: "component---src-pages-ocpi-tsx" */),
  "component---src-pages-ocpp-tsx": () => import("./../../../src/pages/ocpp.tsx" /* webpackChunkName: "component---src-pages-ocpp-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-usecase-fleet-tsx": () => import("./../../../src/pages/usecase/fleet.tsx" /* webpackChunkName: "component---src-pages-usecase-fleet-tsx" */),
  "component---src-pages-usecase-workplace-tsx": () => import("./../../../src/pages/usecase/workplace.tsx" /* webpackChunkName: "component---src-pages-usecase-workplace-tsx" */),
  "component---src-pages-white-label-tsx": () => import("./../../../src/pages/white-label.tsx" /* webpackChunkName: "component---src-pages-white-label-tsx" */)
}

